import __NS_DYNAMIC_REACT_CONTENT___0 from "./unauthed-footer.strings/content.default.js";
import { Content, createScopedReactContentImporter } from "packages/react-content/runtime";
const NS_DYNAMIC_REACT_CONTENT_MANAGER = createScopedReactContentImporter(undefined, {
  "default": () => __NS_DYNAMIC_REACT_CONTENT___0
});
import { createElement, Fragment } from 'react';
import { Bold, Disclosure } from 'legos/typography';
import { IssuerStatement } from 'apps/acp/packages/issuer-statement/react';
import { CopyrightStatement } from 'apps/acp/packages/content-copyright-statement';
import { isAndroid, isIOS, isWeb } from 'packages/platform-detector';
import { createUnAuthedGetBrandingRequest } from 'apps/acp/packages/webapi';
import { useHttpQuery } from 'packages/http-client/react';
import { showMobileAppsSection } from 'apps/acp/packages/mobile-apps-section-detector';
import { limitedUseAccessToken } from 'apps/acp/packages/limited-use-access-token';
import { AdaChatButton } from 'apps/acp/packages/ada-chatbot';
const isCordova = isAndroid() || isIOS();
export const UnauthedFooter = ({
  mfeConfig
}) => {
  const {
    brand
  } = useHttpQuery(limitedUseAccessToken(createUnAuthedGetBrandingRequest()));
  return createElement(Fragment, null, mfeConfig.showLoginTermsAndConditionsDisclosure && createElement(Disclosure, null, mfeConfig.showChangedTermsDepositPrivacyDisclosure && createElement(Content, {
    hash: "53bf7ac7543303c92e6380e113e70be4",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }), !mfeConfig.showChangedTermsDepositPrivacyDisclosure && createElement(Content, {
    hash: "d2d5abed877dab6dd8febf80a495415b",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), mfeConfig.importantPatriotActDisclosure && createElement(Disclosure, {
    dangerouslySetInnerHTML: {
      __html: mfeConfig.importantPatriotActDisclosure
    }
  }), createElement(Disclosure, null, createElement(IssuerStatement, null)), mfeConfig.showMastercardDisclosures && createElement(Disclosure, null, createElement(Content, {
    hash: "33308208c0ae543e4c9ea7a7e6b58c02",
    devVariables: {},
    componentsAndExpressions: [() => !mfeConfig.showCardUseDisclosure && createElement(Fragment, {
      key: "showCardUseDisclosure"
    }, createElement("br", null), createElement("br", null), createElement(Content, {
      hash: "4cf36da8d3a88525f1f1f93c95da603a",
      devVariables: {},
      componentsAndExpressions: [],
      importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
    }))],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), mfeConfig.showShortAppleDisclosure && createElement(Disclosure, null, createElement(Content, {
    hash: "7666ea171c0c6e1e73ee70ed96b1c472",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement("sup", null, children), (...children) => createElement("sup", null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), !mfeConfig.showShortAppleDisclosure && !showMobileAppsSection(mfeConfig.programType || '', brand.ios_app_url) && createElement(Disclosure, null, createElement(Content, {
    hash: "a5ae42bb1d8517f8eaeebfe3a440db87",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement("sup", null, children), (...children) => createElement("sup", null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), !showMobileAppsSection(mfeConfig.programType || '', brand.android_app_url) && createElement(Disclosure, null, createElement(Content, {
    hash: "79465afe7189f1a35151faf42bc15ad4",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), (!showMobileAppsSection(mfeConfig.programType || '', brand.android_app_url) || !showMobileAppsSection(mfeConfig.programType || '', brand.ios_app_url)) && createElement(Disclosure, null, createElement(Content, {
    hash: "10e9ee61a55497952097b530487293c7",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Disclosure, null, createElement(CopyrightStatement, {
    statement: mfeConfig.copyrightStatement
  })), isCordova && createElement(Disclosure, {
    onClick: () => {
      var _a, _b;

      return ((_a = mfeConfig) === null || _a === void 0 ? void 0 : _a.mobileAppVersionOnClick) && ((_b = mfeConfig) === null || _b === void 0 ? void 0 : _b.mobileAppVersionOnClick(true));
    },
    style: {
      textAlign: 'center'
    }
  }, createElement(Content, {
    hash: "b683811d44df39541f45bcf5c200bc06",
    devVariables: {
      mfeConfig: mfeConfig
    },
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), isWeb() && createElement(AdaChatButton, null));
};