export default { 
  h091e373871b08d6042165043d3cd75ce: [[({}, []) => ["Google Play and the Google Play logo are trademarks of Google LLC."], {}]],
  h213c3d98a02fc3e970c517063f0b38ef: [[({
  mfeConfig: mfeConfig
}, [C0]) => ["Version ", C0(mfeConfig.version)], {}]],
  h2a8a9464869578fd2f903982e2ef8b90: [[({}, [C0]) => ["Mastercard and the circles design are trademark of Mastercard International Incorporated.", C0()], {}]],
  h4915a74c469e6b8b14442630494c6d9b: [[({}, []) => ["Login to see Terms & Conditions and Privacy Policy."], {}], [({}, []) => ["Login to see Deposit Account Agreement and Privacy Policy."], {
  "programType": ["consumer_dda"]
}]],
  h84db95f012f948e53ccc5235fbbc4007: [[({}, []) => ["Login to see Terms & Conditions or Deposit Account Agreement and Privacy Policy."], {}]],
  h93b71895c7e5dbf840d7fd393a23dd46: [[({}, []) => ["All other trademarks and service marks belong to their respective owners."], {}]],
  h9ca5d4c045aa93808f89d2c25ed25538: [[({}, []) => ["Card may be used everywhere Debit Mastercard is accepted."], {}]],
  hbcb28b50db53c080faa85e4ab47b033a: [[({}, [C0, C1]) => ["Apple", C0("\xAE"), " and the Apple logo", C1("\xAE"), " are trademarks of Apple Inc., registered in the U.S. and other countries. App Store is a service mark of Apple Inc."], {}]],
  hdc2f77fe841059831cb1c7132c5a32ef: [[({}, [C0, C1]) => ["Apple", C0("\xAE"), " and the Apple logo", C1("\xAE"), " are trademarks of Apple Inc., registered in the U.S. and other countries. Apple Inc."], {}]] 
}