
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.DisclosureStyle_62550ca3a003ac32{color:#959595}`;
styleInject(_css)

/** DisclosureStyle Props */
export type DisclosureStyleCCM = {
  /** DisclosureStyle Component Custom Properties */
  // No custom properties found

  /** DisclosureStyle Modifier Flags */
  // No modifiers classes found
};
/** Base DisclosureStyle component */
export const DisclosureStyle: ComponentCreator<DisclosureStyleCCM> = createComponentCreator({
  "name": "DisclosureStyle",
  "base": "DisclosureStyle_62550ca3a003ac32",
  "prop": {},
  "mod": {}
});

