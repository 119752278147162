export default { 
  h10e9ee61a55497952097b530487293c7: [[({}, []) => ["All other trademarks and service marks belong to their respective owners."], {}]],
  h33308208c0ae543e4c9ea7a7e6b58c02: [[({}, [C0]) => ["Mastercard and the circles design are trademark of Mastercard International Incorporated.", C0()], {}]],
  h4cf36da8d3a88525f1f1f93c95da603a: [[({}, []) => ["Card may be used everywhere Debit Mastercard is accepted."], {}]],
  h53bf7ac7543303c92e6380e113e70be4: [[({}, []) => ["Login to see Terms & Conditions or Deposit Account Agreement and Privacy Policy."], {}]],
  h7666ea171c0c6e1e73ee70ed96b1c472: [[({}, [C0, C1]) => ["Apple", C0("\xAE"), " and the Apple logo", C1("\xAE"), " are trademarks of Apple Inc., registered in the U.S. and other countries. Apple Inc."], {}]],
  h79465afe7189f1a35151faf42bc15ad4: [[({}, []) => ["Google Play and the Google Play logo are trademarks of Google LLC."], {}]],
  ha5ae42bb1d8517f8eaeebfe3a440db87: [[({}, [C0, C1]) => ["Apple", C0("\xAE"), " and the Apple logo", C1("\xAE"), " are trademarks of Apple Inc., registered in the U.S. and other countries. App Store is a service mark of Apple Inc."], {}]],
  hb683811d44df39541f45bcf5c200bc06: [[({
  mfeConfig: mfeConfig
}, [C0]) => ["Version ", C0(mfeConfig.version)], {}]],
  hd2d5abed877dab6dd8febf80a495415b: [[({}, []) => ["Login to see Terms & Conditions and Privacy Policy."], {}], [({}, []) => ["Login to see Deposit Account Agreement and Privacy Policy."], {
  "programType": ["consumer_dda"]
}]] 
}